import { Component } from '@angular/core';

import { ModalController, Platform,MenuController, NavController,ToastController } from '@ionic/angular';

import { AuthService } from 'src/app/services/auth.service'
import { Storage } from "@ionic/storage";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  user_type;
  constructor(  private platform: Platform,
   
    private toastCtrl: ToastController,  private storages: Storage, private menu: MenuController, private authService: AuthService, private navCtrl: NavController,) {
    this.initializeApp();
    this.menu.enable(false);
    this.loadpage();
   }
   initializeApp() {
    this.platform.ready().then(() => {
   

      console.log("Platform ready");

    
    });
    this.platform.backButton.subscribeWithPriority(9999, () => {
      document.addEventListener(
        "backbutton",
        function (event) {
          // this.platform.exitApp();

          // or if that doesn't work, try
          // navigator["app"].exitApp();
          if (
            window.location.pathname == "/home-menu/vendor-home" ||
            window.location.pathname == "/customer-home" ||
            window.location.pathname == "/home-menu/single-vendor-home" ||
            window.location.pathname == "/user-home"  ||
            window.location.pathname == "/authvendor-home" ||
            window.location.pathname == "/home-menu/app-home"
           
          ) {
            console.log("exit", window.location.pathname);
            console.log("hrll");
            navigator["app"].exitApp();
          } else {
            // window.history.back();
            // this.location.back();
            event.preventDefault();
            event.stopPropagation();
            return;
            // this.location.back();
            // this.navController.navigateBack();
            console.log("r");
            // this.location.back();
            // this.navCtrl.back();
            // this.navCtrl.pop();
          }
          // event.preventDefault();
          // event.stopPropagation();

          // console.log("hai");
        },
        false
      );
    });
  }
   loadpage() {
    
     
    this.authService.getToken().then(() => {
      console.log("fgfgff");
      if(this.authService.isLoggedIn) {
        this.storages.get("user_type").then((user_type) => {
          this.user_type=user_type;
         console.log( this.user_type,"tht");
         if(this.user_type=="1" ){
          console.log("fgfg");
          this.navCtrl.navigateRoot('/authvendor-home');
         }else if(this.user_type=="2"){
          this.navCtrl.navigateRoot('/customer-home');
         }else{
          this.navCtrl.navigateRoot('/user-home');
         }
        });
       
      }else{
        this.navCtrl.navigateRoot('/home-menu/app-home');
      }
    });
  }
}
