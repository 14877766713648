import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';

import { tap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Storage } from "@ionic/storage";
import { Observable, throwError, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthIntercepterService {

    protected url   = 'https://app.kizakuhaspatal.co.in';
    protected debug = true;
  
    constructor(private storage: Storage) {}
  
    intercept(
        httpRequest: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
    
  
        // YOU CAN ALSO DO THIS
        // const token = this.authenticationService.getToke()
  // console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiii");
        return from(this.storage.get('token'))
            .pipe(
                switchMap(token => {
                   
  
                    httpRequest = httpRequest.clone({
                        setHeaders: {
                          Accept: `application/json`,
                          // Authorization:  "Bearer" + " " +  this.token
                          Authorization: 'Bearer ' + token,
                        }
                      });
                  
  
                    return next.handle(httpRequest).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                // do nothing for now
                            }
                            return event;
                        }),
                       
                    );
                })
            );
  
  
    }
  
  
  }