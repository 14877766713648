import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'app-home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./pages/auth/otp/otp.module').then( m => m.OtpPageModule)
  },
  // {
  //   path: 'app-home',
  //   loadChildren: () => import('./pages/inner/app-home/app-home.module').then( m => m.AppHomePageModule)
  // },
  {
    path: 'product-list',
    loadChildren: () => import('./pages/inner/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  {
    path: 'single-view',
    loadChildren: () => import('./pages/inner/single-view/single-view.module').then( m => m.SingleViewPageModule)
  },
  {
    path: 'order-confirm',
    loadChildren: () => import('./pages/inner/order-confirm/order-confirm.module').then( m => m.OrderConfirmPageModule)
  },
  {
    path: 'av-reg1',
    loadChildren: () => import('./pages/inner/author-vendor/av-reg1/av-reg1.module').then( m => m.AvReg1PageModule)
  },
  {
    path: 'av-reg2',
    loadChildren: () => import('./pages/inner/author-vendor/av-reg2/av-reg2.module').then( m => m.AvReg2PageModule)
  },
  {
    path: 'av-reg3',
    loadChildren: () => import('./pages/inner/author-vendor/av-reg3/av-reg3.module').then( m => m.AvReg3PageModule)
  },
  {
    path: 'av-otp',
    loadChildren: () => import('./pages/inner/author-vendor/av-otp/av-otp.module').then( m => m.AvOtpPageModule)
  },
  {
    path: 'av-reg4',
    loadChildren: () => import('./pages/inner/author-vendor/av-reg4/av-reg4.module').then( m => m.AvReg4PageModule)
  },
  {
    path: 'av-reg5',
    loadChildren: () => import('./pages/inner/author-vendor/av-reg5/av-reg5.module').then( m => m.AvReg5PageModule)
  },
  {
    path: 'av-review',
    loadChildren: () => import('./pages/inner/author-vendor/av-review/av-review.module').then( m => m.AvReviewPageModule)
  },
  {
    path: 'prdct-upload',
    loadChildren: () => import('./pages/inner/author-vendor/inside/prdct-upload/prdct-upload.module').then( m => m.PrdctUploadPageModule)
  },
  {
    path: 'authvendor-home',
    loadChildren: () => import('./pages/inner/author-vendor/inside/authvendor-home/authvendor-home.module').then( m => m.AuthvendorHomePageModule)
  },
  {
    path: 'user-home',
    loadChildren: () => import('./pages/inner/user-home/user-home.module').then( m => m.UserHomePageModule)
  },
  {
    path: 'user-productupload',
    loadChildren: () => import('./pages/inner/author-vendor/inside/user-productupload/user-productupload.module').then( m => m.UserProductuploadPageModule)
  },
  {
    path: 'customer-home',
    loadChildren: () => import('./pages/inner/author-vendor/inside/customer-home/customer-home.module').then( m => m.CustomerHomePageModule)
  },
  {
    path: 'order-varience',
    loadChildren: () => import('./pages/inner/order-varience/order-varience.module').then( m => m.OrderVariencePageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/inner/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'varienceuser',
    loadChildren: () => import('./pages/inner/varienceuser/varienceuser.module').then( m => m.VarienceuserPageModule)
  },
  {
    path: 'category-product',
    loadChildren: () => import('./pages/inner/category-product/category-product.module').then( m => m.CategoryProductPageModule)
  },
  {
    path: 'single-product',
    loadChildren: () => import('./pages/inner/single-product/single-product.module').then( m => m.SingleProductPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/inner/payment/payment.module').then( m => m.PaymentPageModule)
  },
  // {
  //   path: 'vendor-home',
  //   loadChildren: () => import('./pages/inner/vendor-home/vendor-home.module').then( m => m.VendorHomePageModule)
  // },
  // {
  //   path: 'single-vendor-home',
  //   loadChildren: () => import('./pages/inner/single-vendor-home/single-vendor-home.module').then( m => m.SingleVendorHomePageModule)
  // },
  {
    path: 'home-menu',
    loadChildren: () => import('./pages/inner/home-menu/home-menu.module').then( m => m.HomeMenuPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/inner/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/inner/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'buynow-det',
    loadChildren: () => import('./pages/inner/buynow-det/buynow-det.module').then( m => m.BuynowDetPageModule)
  },
  {
    path: 'buynow-completed',
    loadChildren: () => import('./pages/inner/buynow-completed/buynow-completed.module').then( m => m.BuynowCompletedPageModule)
  },
  {
    path: 'return-policy',
    loadChildren: () => import('./pages/inner/return-policy/return-policy.module').then( m => m.ReturnPolicyPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./pages/inner/terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/inner/contact/contact.module').then( m => m.ContactPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
