import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { tap } from "rxjs/operators";
import { Storage } from "@ionic/storage";
import { Platform, NavController } from "@ionic/angular";
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn = false;
  token: any;
  data:any;
  BASE_URL = environment.rootUrl;
  // BASE_URL="http://127.0.0.1:8000/api/";

  constructor(private httpClient: HttpClient,  private storages: Storage,   private navCtrl: NavController) {}
  registration(user_type:any,name:any,mobile_no:any,email:any,password:any,district_id:any){
    return this.httpClient.post<any>(`${this.BASE_URL}register`,{
     
      user_type: user_type,
      name: name,
      mobile_no: mobile_no,
      email: email,
      password: password,
      district_id:district_id


    },);
  }
  vendorregistration(user_id:any,business_name:any,address:any,gst_no:any,Business_category:any){
    return this.httpClient.post<any>(`${this.BASE_URL}vendorregistrtion1`,{
      // this.user_id, this.business_name,this.address,this.gst_no,this.Category
      user_id: user_id,
      business_name: business_name,
      address: address,
      gst_no: gst_no,
      Business_category: Business_category,


    },);
  }
  vendorregistration2(user_id:any,licence_copy:any,gst_copy:any,shopy_copy:any){
    return this.httpClient.post<any>(`${this.BASE_URL}vendorregistrtion2`,{
     
      // this.user_id, this.business_name,this.address,this.gst_no,this.Category
      user_id: user_id,
      licensce: licence_copy,
      gst_certificate: gst_copy,
      shop_pictures: shopy_copy,
    


    },);
  }
  verifyotp(user_id:any,otp:any){
    return this.httpClient.post<any>(`${this.BASE_URL}verifyOtpapp`,{
     
      user_id: user_id,
      otp: otp,
    


    },);
  }
  onFileChangedlicence(file: any) {
    var myFormData = new FormData();
    const headers = new HttpHeaders();
  headers.append('Content-Type', 'multipart/form-data');
  headers.append('Accept', 'application/json');
  myFormData.append('shop_pic', file);
   
   
    return this.httpClient.post(
      `${this.BASE_URL}uploadfiles`,

      myFormData,
      { headers: headers }
    );
  }
  login(email: String, password: String) {
    console.log(email);
    return this.httpClient
      .post(`${this.BASE_URL}login`, {
        email: email,
        password: password,
      })
      .pipe(
        tap((data) => {
          this.data=data;
          console.log(this.data)
   
          this.storages.set("vendor_userId",this.data.user.id);
          this.storages.set("user_name",this.data.user.name);
          this.storages.set("district_id",this.data.user.district_id);
          this.storages.set("user_type",this.data.user.user_type);
          this.storages.set("token",this.data.token);
          this.storages.set("tokenpass",this.data.token);
          if(this.data.vendor){
            this.storages.set("admin_status",this.data.vendor.admin_status);
            this.storages.set("vendor_id",this.data.vendor.id);
            this.storages.set("vendor_person",this.data.vendor.contact_person);
  
          }
      
   
          this.token = this.data.data;
          this.isLoggedIn = true;
          return data;
        })
      );
  }
  tokenlogout() {
    this.storages.remove("token");
    this.storages.remove("district_id");
   
    this.isLoggedIn = false;
    delete this.token;
    this.navCtrl.navigateRoot("/home-menu/app-home");
  }
  getToken() {
    return this.storages.get('token').then(
      data => {
        this.token = data;
        if(this.token != null) {
          this.isLoggedIn=true;
        } else {
          this.isLoggedIn=false;
        }
      },
      error => {
        this.token = null;
        this.isLoggedIn=false;
      }
    );
  }
  districtproduct() {
    return this.httpClient.get(
      `${this.BASE_URL}district`
    );
  }
}
